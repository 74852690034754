import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Typography, Stack, Divider } from '@mui/material';
import { formatMoney } from '../../helpers';

const CurrentBehaviorHeader = (
  {
    selectedBehaviorId,
    availableOptions,
    setSelectedBehaviorId,
    totalDebtAmount,
    dateInform,
  },
) => (
  <Stack direction="row" spacing={12} justifyContent="center" divider={<Divider orientation="vertical" flexItem />}>
    <Stack direction="column" spacing={0.5} alignItems="center" alignSelf="center">
      <Typography variant="h5"><b>Monto Total Deuda</b></Typography>
      <Typography variant="h6">${formatMoney(totalDebtAmount)}</Typography>
    </Stack>
    <Stack direction="column" spacing={0.5} alignItems="center" alignSelf="center">
      <Typography variant="h5"><b>Fecha de Deuda</b></Typography>
      <Typography variant="h6">{dateInform}</Typography>
    </Stack>
    <Stack spacing={0.5} alignItems="center">
      <Typography><b>Fecha de Obtención</b></Typography>
      <Select
        id="report-selector"
        value={selectedBehaviorId}
        onChange={(e) => setSelectedBehaviorId(e.target.value)}
        variant="outlined"
        placeholder="Seleccione un reporte"
      >
        {availableOptions.map((option) => (
          <MenuItem
            key={option.key}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  </Stack>
);

CurrentBehaviorHeader.propTypes = {
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
  })),
  selectedBehaviorId: PropTypes.string,
  setSelectedBehaviorId: PropTypes.func.isRequired,
  totalDebtAmount: PropTypes.number,
  dateInform: PropTypes.string,
};

CurrentBehaviorHeader.defaultProps = {
  selectedBehaviorId: '',
  totalDebtAmount: 0,
  dateInform: '',
  availableOptions: [],
};

export default CurrentBehaviorHeader;
